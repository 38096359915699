<template>
  <div class="unsubscribe-wrap">
    <div class="unsubscribe-div__container">
      <div class="unsubscribe-div__title">
        <div class="title-text">{{$t('subscribe.confirm_pd_info')}}</div>
        <div>
          <span class="back-btn" @click="router.back(-1)" >
            <svg-icon name="fanhui"></svg-icon>
            {{$t('subscribe.back')}}
          </span>
        </div>
      </div>
      <div class="unsubscribe-div__main">
        <div class="unsubscribe-div__label">
          <div class="unsubscribe-div__left product-title">{{$t('subscribe.product_title')}} {{desc}}</div>
          <!-- <div class="unsubscribe-div__right">{{ $isZh() ? '¥' : '$' }} {{routeQuery.total}}</div> -->
        </div>
        <div class="unsubscribe-div__label">
          <div class="unsubscribe-div__left">{{routeQuery.order_type === 'renew' ? $tLabel($t('subscribe.renewal_time')) : $tLabel($t('subscribe.updated_time'))}}</div>
          <div class="unsubscribe-div__right">{{ ($isZh() ? kvZh[routeQuery.currentTime] : kvEn[routeQuery.currentTime]) || routeQuery.cycle || $t('subscribe.upgrade_duration',[routeQuery.duration])}}</div>
        </div>
        <!-- <div class="unsubscribe-div__left">
          <div>服务开通时间</div>
          <div>{{routeQuery.startTime}}</div>
        </div>
        <div class="unsubscribe-div__left">
          <div>服务结束时间</div>
          <div>{{routeQuery.endTime}}</div>
        </div> -->
        <div class="unsubscribe-div__label">
          <div class="unsubscribe-div__left">{{$t('subscribe.service_time')}}</div>
          <div class="unsubscribe-div__right">{{routeQuery.startTime}}<span style="color: var(--color-text-3); padding: 0 16px;">-</span>{{routeQuery.endTime}}</div>
        </div>
        <div class="unsubscribe-div__label">
          <div class="unsubscribe-div__left">{{$t('subscribe.total_items')}}</div>
          <div class="unsubscribe-div__right">{{$t('subscribe.orginal_price',[formatNum(routeQuery.total)])}}</div>
        </div>
        <div class="unsubscribe-div__label" v-if="routeQuery.discountMoney !== '0'">
          <div class="unsubscribe-div__left">{{$t('subscribe.discount')}}</div>
          <div class="unsubscribe-div__right"><span style="padding-right: 10px;">{{$t('subscribe.annual_discount')}}</span>{{ formatNum(routeQuery.discountMoney) && formatNum(routeQuery.discountMoney) !== '-' ? '-' : '' }}{{formatNum(routeQuery.discountMoney)}}</div>
        </div>
        <div class="unsubscribe-div__label" style="margin-top: 27px;">
          <div class="unsubscribe-div__left">{{$t('subscribe.actually_paid')}}</div>
          <div class="unsubscribe-div__right" style="margin-left: 20px;font-size: 24px;color: #00AB7A;font-family: Helvetica;"><span style="font-size: 14px;">{{ $isZh() ? '¥' : '$' }} </span>{{formatNum(routeQuery.payAmount)}}</div>
        </div>
        <div class="unsubscribe-div__label unsubscribe-div__bottom">
          <div class="unsubscribe-div__right">
            <el-button
              :class="{isDisabled:'isDisabled'}"
              class="uploadConfig-btn"
              type="primary"
              @click="submit"
            >{{$t('subscribe.submit_order')}}</el-button>
          </div>
        </div>
      </div>
      <orderDialog @closeDialog="closeDialog" v-if="showLog"></orderDialog>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import orderDialog from '@/components/order/dialog.vue'
import { message } from 'hooks/useUI'
import { getPaymentOrderAdd, getPaymentOrderCheck, getPaymentOrderUpgrade, getPaymentOrderRenew } from '@/services/order'
import { t, isZh } from 'app/i18n'

const route = useRoute()
const router = useRouter()
const showLog = ref(false)
const kvEn = {
  professional: 'Pro plan',
  enterprise: 'Pro Plus plan',
  one_month: 'One month',
  three_month: 'Three months',
  six_month: 'Six months',
  one_year: 'One year',
  two_year: 'Two years'
}

const kvZh = {
  professional: '专业版',
  enterprise: '专业增强版',
  one_month: '1个月',
  three_month: '3个月',
  six_month: '6个月',
  one_year: '1年',
  two_year: '2年'
}

const routeQuery = computed(() => {
  console.log(route.query)
  return route.query || {}
})
const desc = computed(() => {
  if (routeQuery.value.order_type === 'renew') {
    return t('subscribe.product_desc_renew', [(isZh() ? kvZh[routeQuery.value.type] : kvEn[routeQuery.value.type]) || routeQuery.value.label])
  }
  if (routeQuery.value.order_type === 'upgrade') {
    return t('subscribe.product_desc_upgrade')
  }
  return t('subscribe.product_desc_free', [(isZh() ? kvZh[routeQuery.value.type] : kvEn[routeQuery.value.type]) || routeQuery.value.label])
})

function submit () {
  if (!isZh()) {
    message('International payment is being prepared, so stay tuned!', 'error')
    return
  }
  const params = {
    product_name: routeQuery.value.type,
    rec_amount: Number(routeQuery.value.payAmount),
    order_type: routeQuery.value.order_type,
    duration: Number(routeQuery.value.months),
    product_desc: 'shaxiangyun'
  }
  getPaymentOrderCheck().then(res => {
    if (res.status !== 0) {
      showLog.value = true
    } else {
      switch (routeQuery.value.order_type) {
        case 'renew':
          renew(params)
          break
        case 'purchase':
          addOrder(params)
          break
        case 'upgrade':
          upgrade(params)
          break
      }
    }
  })
}
function closeDialog (flag) {
  showLog.value = false
  if (!flag) return
  router.push({
    name: 'order'
  })
}
function addOrder (params) {
  getPaymentOrderAdd(params).then(res => {
    if (res.status === 1) return
    setOption(res)
  }).catch(e => {
    console.log('订单提交失败')
  })
}
function renew (params) {
  getPaymentOrderRenew(params).then(res => {
    if (res.status === 1) return
    setOption(res)
  }).catch(e => {
    console.log('订单提交失败')
  })
}
function upgrade (params) {
  getPaymentOrderUpgrade(params).then(res => {
    if (res.status === 1) return
    setOption(res)
  }).catch(e => {
    console.log('订单提交失败')
  })
}
function formatNum(s, n = 0) {
  if (!s) {
    return '-'
  }
  /*
   * 参数说明：
   * s：要格式化的数字
   * n：保留几位小数
   * */
  n = n > 0 && n <= 20 ? n : 2
  s = parseFloat((s + '').replace(/[^\d.-]/g, '')).toFixed(n) + ''
  var l = s
    .split('.')[0]
    .split('')
    .reverse()
  // var r = s.split('.')[1]
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
  }
  return t
    .split('')
    .reverse()
    .join('')
}
function setOption (res) {
  const { rec_amount, order_id, create_time } = res
  // this.setOrderInfo({
  //   rec_amount,
  //   order_id,
  //   order_type: this.order_type
  // })
  router.push({
    name: 'confimOrder',
    query: {
      order_id,
      order_type: routeQuery.value.order_type,
      startTime: create_time,
      rec_amount: rec_amount
    }
  })
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.unsubscribe-wrap {
  // padding: 16px;
  min-height: calc(100% - 100px);
  margin: 20px 16px 16px 16px;
  background: var(--detect-config-bg);
}
.unsubscribe-div__container {
  width: 1000px;
  margin: 0 auto;
  font-size: 14px;
  color: var( --color-unkown-text);
  min-height: 100%;
  .back-btn {
    color: #00AB7A;
    // text-decoration: none;
    cursor: pointer;
  }

  .unsubscribe-div__title {
    width: 100%;
    height: 100px;
    line-height: 100px;
    // display: flex;
    position: relative;
    font-size: 14px;
    .title-text {
      font-weight: 600;
      width: 100%;
      font-size: 24px;
      text-align: center;
      color: var(--color-text-1);
      font-weight: 600;
    }
    div {
      &:last-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }
  }

  .unsubscribe-div__main {
    width: 100%;
    line-height: 22px;
    // padding: 26px 24px 0 24px;
    padding-top: 22px;
    box-sizing: border-box;
    color: var(--color-text-3);
    .product-title {
      font-size: 16px;
      color: var(--color-text-1);
    }

    .unsubscribe-div__bottom {
      margin-top: 50px !important;
      position: relative;

      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: -24px;
        background: var(--color-border-2);
      }

      .unsubscribe-div__left {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;

          &:last-child {
            margin-left: 20px;
            font-size: 30px;
            color: #00AB7A;
          }
        }
      }

      .uploadConfig-btn {
        background: #00AB7A;
        box-shadow: 2px 3px 4px 0 rgba(5, 11, 48, 0.4);
        border-radius: 2px;
        font-size: 16px;
        width: 120px;
        // padding: 0 30px;
        height: 40px;
        border: none;
        color: var(--color-text-1);
      }
    }

    .unsubscribe-div__label {
      display: flex;
      margin-top: 16px;

      .unsubscribe-div__right {
        &:last-child {
          flex: 1;
          color: var(--color-text-1);
          text-align: right;
          font-size: 14px;
        }
      }
    }
  }
}

[data-theme='light'] {
  .unsubscribe-div__container .unsubscribe-div__main .unsubscribe-div__bottom .uploadConfig-btn {
    color: #ffffff;
  }
}
</style>
